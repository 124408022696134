import { Apoiador } from "./../../models/apoiador.model";
import { Cabecalho } from "./../../models/cabecalho.model";
import { LoadingService } from "./../../services/loading.service";
import { SiteService } from "./../../services/site.service";
import { Subscription, forkJoin } from "rxjs";
import { Patrocinador } from "./../../models/patrocinador.model";
import { Component, OnInit } from "@angular/core";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-patrocinadores",
  templateUrl: "./patrocinadores.component.html",
  styleUrls: ["./patrocinadores.component.scss"],
})
export class PatrocinadoresComponent implements OnInit {
  patrocinadores: Patrocinador[] = [];
  apoiadores: Apoiador[] = [];

  cabecalho: Cabecalho = new Cabecalho();

  forkSubscription: Subscription;

  constructor(
    public siteService: SiteService,
    public loadingService: LoadingService,
    public helper: HelperService
  ) {}

  ngOnInit() {
    if (localStorage.getItem("cabecalhos")) {
      this.cabecalho = JSON.parse(localStorage.getItem("cabecalhos"));
    }
    let buscarPatrocinadores = this.siteService.buscarPatrocinadores(-99, -99);
    let buscarApoiadores = this.siteService.buscarApoiadores(-99, -99);

    this.fork([buscarPatrocinadores, buscarApoiadores]);
    this.helper.setCanonicalURL();
  }

  ngOnDestroy() {
    this.forkSubscription.unsubscribe();
  }

  fork(reqs) {
    this.loadingService.present();
    this.forkSubscription = forkJoin(reqs).subscribe(
      (res: any) => {
        this.patrocinadores = res[0];
        this.apoiadores = res[1];
        this.loadingService.dismiss();
      },
      (e) => this.loadingService.dismiss()
    );
  }
}
