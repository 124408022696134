<section class="home-banner">
    <ngu-carousel #banner [inputs]="carouselBanner" [dataSource]="banners" class="banner">
        <ngu-item NguCarouselItem class="banner-item" *nguCarouselDef="let item; let i = index">
            <a [href]="item.link">
                <img [src]="item.imagem" [alt]="'banner' + i" loading="lazy" [title]="'banner' + i" width="1920"
                    height="800">

                <div class="container">
                    <div class="banner-item-wrapper">
                        <h2 class="banner-item__title" [innerHtml]="item.titulo"></h2>
                        <a *ngIf="item.link" [href]="item.link" class="btn btn-default" title="Ver mais">ver mais</a>
                    </div>
                </div>
            </a>
        </ngu-item>

        <ul class="banner-dots" NguCarouselPoint>
            <li *ngFor="let point of banner.pointNumbers; let i = index" [class.active]="i == banner.activePoint"
                (click)="banner.moveTo(i)" class="banner-dots__item">
            </li>
        </ul>
    </ngu-carousel>

    <div class="banner-scroll">
        <span class="banner-scroll__text">
            Desça para ver mais
        </span>
        <div class="banner-scroll__line"></div>
        <img src="assets/icon/scroll.svg" title="Scroll icon" loading="lazy" alt="Scroll icon"
            class="banner-scroll__icon">
    </div>
</section>

<section class="home-teams">
    <div class="container">
        <div class="home-teams__title">
            <div class="title title-default title-dark">
                <h1 class="title__name">
                    Nossos <strong>times</strong>
                </h1>
                <p class="title__content">
                    Conheça as modalidades<br />em que atuamos
                </p>
            </div>
            <a routerLink="/agenda" class="btn-link">
                VER AGENDA DOS TIMES
            </a>
        </div>
    </div>

    <div class="box-teams-list">
        <ng-container *ngFor="let jogo of jogos; let i = index;">
            <div class="box-teams-item" *ngIf="i < 3">
                <div class="box-teams-item__content">
                    <h2 class="box-teams-item__title">{{jogo.nome}}</h2>
                    <a (click)="verJogo(jogo)" [title]="jogo.nome" class="box-teams-item__button btn btn-default">VER
                        EQUIPE</a>
                </div>
                <div class="box-teams-item__image">
                    <img [src]="jogo.imagem" [alt]="jogo.nome" loading="lazy" width="1920" height="500"
                        [title]="jogo.nome">
                </div>
            </div>
        </ng-container>
    </div>

    <div class="container">
        <a routerLink="/todos/%/times" class="home-teams__button btn btn-default btn-large-x btn-block">ver todos
            times</a>
    </div>

</section>

<section class="home-about" [style.minHeight]="sobreImageHeight + 'px'">
    <div class="container">
        <div class="home-about-wrapper">
            <div class="home-about__image">
                <img [src]="sobre.imagemPequena" [alt]="sobre.titulo" [title]="sobre.titulo" loading="lazy" #sobreImg>
            </div>
            <div class="home-about__content">
                <h2 class="home-about__title">
                    {{sobre.titulo}}
                </h2>
                <div class="home-about__description" [innerHtml]="sobre.descricao1"></div>
                <a routerLink="/sobre" class="btn btn-default" title="Saiba mais">Saiba mais</a>
            </div>
        </div>
        <div class="home-about__brand">
            <img src="assets/logo.svg" title="Black Dragons | e-Sports" loading="lazy" alt="Black Dragons | e-Sports">
        </div>
    </div>
</section>

<section class="home-products" *ngIf="produtos.length">
    <div class="home-products-wrapper">
        <div class="home-products__image">
            <img src="assets/produto1.jpg" alt="Produtos" title="Produtos" loading="lazy">
        </div>
        <div class="home-products-list">
            <div class="product-box" *ngFor="let p of produtos; let i = index;" [class.half]="i == 1"
                [style.backgroundImage]="'url(' + p.imagem + ')'">
                <div class="product-box__content">
                    <h3 class="product-box__title">{{p.nome}}</h3>
                    <h2 class="product-box__price">{{p.valor | currency:'BRL':true}}</h2>
                    <a [href]="p.link" target="_blank" class="btn btn-default btn-small" title="comprar">comprar</a>
                </div>
            </div>
            <div class="product-box half">
                <a href="https://www.fallenstore.com.br/categoria/83" target="_blank" class="product-box__link"
                    title="ver loja completa">ver loja completa</a>
            </div>
        </div>
    </div>
</section>

<section class="home-matches">
    <div class="container">
        <div class="title title-default">
            <h2 class="title__name">
                <strong>Partidas</strong>
            </h2>
            <p class="title__content">
                Fique ligado nas<br />próximas partidas
            </p>
        </div>
        <div class="home-matches-filter">
            <!-- <div class="toggle">
                <div class="toggle-item">
                    <input type="radio" name="toggle-partidas" [(ngModel)]="togglePartidas"
                        (ngModelChange)="togglePartidasChange($event)" id="toggle-partidas-recentes" value="1" checked>
                    <label for="toggle-partidas-recentes">RECENTES</label>
                </div>
                <div class="toggle-item">
                    <input type="radio" name="toggle-partidas" [(ngModel)]="togglePartidas"
                        (ngModelChange)="togglePartidasChange($event)" id="toggle-partidas-proximas" value="0">
                    <label for="toggle-partidas-proximas">PRÓXIMAS</label>
                </div>
            </div> -->
            <div class="filters">
                <h3 class="filters__title">Filtros:&nbsp;&nbsp;</h3>
                <div class="filters-list">
                    <div class="filters-item">
                        <input type="radio" [(ngModel)]="idJogo" (ngModelChange)="idJogoChange($event)"
                            name="filtrosPartidas" id="filtrosPartidasTodos" value="%">
                        <label for="filtrosPartidasTodos">Todos</label>
                    </div>
                    <div class="filters-item" *ngFor="let jogo of jogos; let i = index;"
                        [class.hidden]="i > 1 && !openFilter">
                        <input type="radio" [(ngModel)]="idJogo" (ngModelChange)="idJogoChange($event)"
                            name="filtrosPartidas" [id]="'filtrosPartidas' + jogo.id" [value]="jogo.id">
                        <label [for]="'filtrosPartidas' + jogo.id">{{jogo.nome}}</label>
                    </div>
                </div>
                <button class="btn btn-default btn-small btn-block hidden-desktop"
                    (click)="openFilter = !openFilter">mostrar
                    {{openFilter ? 'menos' : 'mais'}}</button>
            </div>
        </div>
        <div class="home-matches-wrapper">
            <div class="matches-item" [title]="partida.liga" *ngFor="let partida of partidas; let i = index"
                [class.matches-item--royale]="partida.jogo.battleRoyale === 'S'">
                <a class="matches-item__live" title="live" [href]="partida.link" target="_blank"
                    *ngIf="partida.aoVivo === 'S'">AO VIVO</a>
                <div class="matches-item__icon">
                    <img [src]="partida.liga.imagem" [alt]="partida.liga.nome" [title]="partida.liga.nome"
                        loading="lazy">
                </div>
                <div class="matches-item__logo">
                    <img src="assets/logo.svg" alt="Black Dragons" title="Black Dragons" loading="lazy">
                    <span>Black Dragons</span>
                </div>
                <div class="matches-item__content">
                    <h3 class="matches-item__game">{{partida.jogo?.nome}}</h3>
                    <h2 class="matches-item__score" [class.empty]="partida.placarBlackDragons == null"
                        [class.wo]="partida.resultado === 'WD' || partida.resultado === 'W'">
                        <ng-container *ngIf="partida.resultado === 'W'">
                            W.O Vitória
                        </ng-container>
                        <ng-container *ngIf="partida.resultado === 'WD'">
                            W.O Derrota
                        </ng-container>
                        <ng-container *ngIf="partida.resultado !== 'W' && partida.resultado !== 'WD'">
                            <ng-container *ngIf="partida.jogo.battleRoyale === 'N'">
                                {{partida.placarBlackDragons !== null ? partida.placarBlackDragons + '-' + partida.placarAdversario : 'vs'}}
                            </ng-container>
                            <ng-container *ngIf="partida.jogo.battleRoyale === 'S'">
                                {{partida.placarBlackDragons !== null ? partida.placarBlackDragons + 'º' : 'EM BREVE'}}
                            </ng-container>
                        </ng-container>
                    </h2>
                    <span class="matches-item__league">{{partida.liga?.nome}}</span>
                    <small class="matches-item__date">
                        {{helper.moment(partida.data).format('DD.MM.YYYY')}}
                        <ng-container *ngIf="partida.hora">
                            {{helper.moment(partida.hora).format('HH:mm')}}
                        </ng-container>
                    </small>
                </div>
                <div class="matches-item__logo">
                    <ng-container *ngIf="partida.jogo.battleRoyale === 'N'">
                        <img [src]="partida.imagemEscudoAdversario || 'assets/brazao-placeholder.png'"
                            [alt]="partida.liga" [title]="partida.liga" loading="lazy">
                        <span>{{partida.nomeAdversario}}</span>
                    </ng-container>
                    <p *ngIf="partida.jogo.battleRoyale === 'S'">
                        {{partida.descricaoBattleRoyale}}
                    </p>
                </div>
            </div>
        </div>
        <a routerLink="/agenda" class="btn btn-default btn-large-x btn-block">ver calendário completo</a>
    </div>
</section>

<section class="home-blog">
    <div class="container">
        <div class="home-blog__title">
            <h2>Notícias em <strong>destaque</strong></h2>
            <a routerLink="/noticias">Ver todas as notícias <i class="fas fa-arrow-right"></i></a>
        </div>
        <div class="blog-list">
            <div class="blog-item" *ngFor="let n of noticias; let i = index;">
                <a [routerLink]="['/noticias', n.slug]" class="blog-item__image">
                    <img [defaultImage]="'assets/image-placeholder.gif'"
                        [lazyLoad]="helper.renderImageHeight(n.imagem, 360)"
                        [errorImage]="'assets/image-error-placeholder.jpg'" [alt]="n.titulo" [title]="n.titulo"
                        loading="lazy" height="360" width="50%">
                </a>
                <div class="blog-item__content">
                    <ul class="blog-item__tags">
                        <li>{{n.jogo?.nome}}</li>
                    </ul>
                    <h3 class="blog-item__title">{{n.titulo}}</h3>
                    <p class="blog-item__description">
                        {{n.descricaoSimples | slice:0:180}}{{n.descricaoSimples.length >= 1 ? '...' : ''}}
                    </p>
                    <a [routerLink]="['/noticias', n.slug]" class="btn btn-default btn-small" title="continuar lendo">
                        CONTINUAR LENDO
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-sponsors">
    <div class="container">
        <h2 class="home-sponsors__title">PATROCINADORES</h2>
        <div class="home-sponsors-wrapper">
            <div class="sponsors-item" *ngFor="let patrocinador of patrocinadores">
                <img [src]="patrocinador.imagem" [alt]="patrocinador.nome" [title]="patrocinador.nome" loading="lazy">
            </div>
        </div>
    </div>
</section>