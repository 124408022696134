import { Cabecalho } from './../models/cabecalho.model';
import { Jogo } from './../models/jogo.model';
import { Mensagem } from './../models/mensagem.model';
import { CadastroGeral } from './../models/cadastro-geral.model';
import { ContatoGeral } from './../models/contato-geral.model';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Noticia } from '../models/noticia.model';
import { Sobre } from '../models/sobre.model';
import { Time } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(
    public api: ApiService
  ) { }

  buscarContatoGeral(): Observable<ContatoGeral> {
    return this.api.get('/site/contatoGeral/buscar');
  }

  buscarCadastroGeral(): Observable<CadastroGeral> {
    return this.api.get('/site/cadastroGeral/buscar');
  }

  buscarBanners(): Observable<any> {
    return this.api.get('/site/banner/buscar');
  }

  buscarNoticias(numeroPagina: number, registrosPorPagina: number, idJogo: string = '%'): Observable<any> {
    return this.api.get(`/site/noticia/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&idJogo=${idJogo}`);
  }

  buscarNoticia(slug: string): Observable<Noticia> {
    return this.api.get(`/site/noticia/buscar?slug=${slug}`);
  }

  buscarUltimasNoticias(numeroPagina: number, registrosPorPagina: number, idNoticia: number): Observable<any> {
    return this.api.get(`/site/noticia/buscarUltimas?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&idNoticia=${idNoticia}`)
  }

  buscarSobre(): Observable<Sobre> {
    return this.api.get(`/site/sobre/buscar`);
  }

  buscarCabecalho(): Observable<Cabecalho> {
    return this.api.get(`/site/cabecalho/buscar`);
  }

  buscarJogos(numeroPagina: number, registrosPorPagina: number): Observable<any> {
    return this.api.get(`/site/jogo/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarJogo(id: number): Observable<Jogo> {
    return this.api.get(`/site/jogo/buscar?id=${id}`);
  }

  buscarTimes(numeroPagina: number, registrosPorPagina: number, idJogo: string = '%'): Observable<any> {
    return this.api.get(`/site/time/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&idJogo=${idJogo}`);
  }

  buscarTime(slug: string): Observable<Time> {
    return this.api.get(`/site/time/buscar?slug=${slug}`);
  }

  buscarEquipe(numeroPagina: number, registrosPorPagina: number): Observable<any> {
    return this.api.get(`/site/equipe/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarProdutos(numeroPagina: number, registrosPorPagina: number): Observable<any> {
    return this.api.get(`/site/produto/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarPatrocinadores(numeroPagina: number, registrosPorPagina: number): Observable<any> {
    return this.api.get(`/site/patrocinador/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarApoiadores(numeroPagina: number, registrosPorPagina: number): Observable<any> {
    return this.api.get(`/site/apoiadores/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarPartidas(numeroPagina: number, registrosPorPagina: number, idJogo: string = '%', data: string = '', pegaAnteriores: boolean): Observable<any> {
    return this.api.get(`/site/partida/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&idJogo=${idJogo}&data=${data}&pegaAnteriores=${pegaAnteriores}`);
  }

  buscarPartidasHome(numeroPagina: number, registrosPorPagina: number, idJogo: string = '%', data: string = ''): Observable<any> {
    return this.api.get(`/site/partida/buscarHome?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&idJogo=${idJogo}&data=${data}`);
  }

  buscarJogadores(numeroPagina: number, registrosPorPagina: number, idTime: string): Observable<any> {
    return this.api.get(`/site/jogador/buscar/?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&idTime=${idTime}`);
  }

  buscarLinhasDoTempo(numeroPagina: number, registrosPorPagina: number): Observable<any> {
    return this.api.get(`/site/linhaDoTempo/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarGaleria(numeroPagina: number, registrosPorPagina: number, idJogo: string = '%', tipo: string = 'F', plataforma: string = 'D'): Observable<any> {
    return this.api.get(`/site/galeria/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&idJogo=${idJogo}&tipo=${tipo}&plataforma=${plataforma}`);
  }

  buscarLives(numeroPagina: number, registrosPorPagina: number): Observable<any> {
    return this.api.get(`/site/live/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  inserirContatoMensagem(mensagem: Mensagem): Observable<any> {
    return this.api.post(`/site/contatoMensagem/inserir`, mensagem);
  }

  buscarDestaques(numeroPagina: number, registrosPorPagina: number, idJogo: string = '%'): Observable<any> {
    return this.api.get(`/site/noticia/buscarDestaques?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&idJogo=${idJogo}`);
  }

  buscarConfiguracoesEmJogo(idJogo: number, idJogador: number): Observable<any> {
    return this.api.get(`/site/configuracaoEmJogo/buscar/jogador?idJogo=${idJogo}&idJogador=${idJogador}`)
  }
}
