import { Cabecalho } from './../../models/cabecalho.model';
import { Jogo } from './../../models/jogo.model';
import { LoadingService } from './../../services/loading.service';
import { SiteService } from './../../services/site.service';
import { Subscription } from 'rxjs';
import { Galeria } from './../../models/galeria.model';
import { Component, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-comunidade',
  templateUrl: './comunidade.component.html',
  styleUrls: ['./comunidade.component.scss'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500)
      ]),
      transition(':leave',
        animate(500, style({ opacity: 0 })))
    ])
  ]
})
export class ComunidadeComponent implements OnInit {

  galeria: any[] = [];
  jogos: Jogo[] = [];

  buscarJogosSubscription: Subscription;
  buscarGaleriaSubscription: Subscription;

  p: number = 1;
  totalPages: number;
  idJogo: string = '%';
  tipo: string = 'F';
  plataforma: string = 'D';

  cabecalho: Cabecalho = new Cabecalho();

  openFilter: boolean = false;

  constructor(
    public _lightbox: Lightbox,
    public _lightboxConfig: LightboxConfig,
    public siteService: SiteService,
    public loadingService: LoadingService,
    public helper: HelperService,
  ) {
  }

  ngOnInit() {

    if (localStorage.getItem('cabecalhos')) {
      this.cabecalho = JSON.parse(localStorage.getItem('cabecalhos'));
    }

    this.buscarGaleria();
    this.buscarJogos();
    this.helper.setCanonicalURL();
  }

  ngOnDestroy() {
    this.buscarJogosSubscription.unsubscribe();
    this.buscarGaleriaSubscription.unsubscribe();
  }

  buscarGaleria() {
    this.galeria = [];
    this.buscarGaleriaSubscription = this.siteService.buscarGaleria(this.p, 12, this.idJogo, this.tipo, this.plataforma)
      .subscribe((res: any) => {
        this.totalPages = res.numeroPaginas * 12;
        res.galerias.forEach((img: Galeria) => {
          const album = {
            src: img.imagem,
            caption: '',
            thumb: this.helper.renderImage(img.imagem, 340)
          }
          this.galeria.push(album);
        });
      });
  }

  buscarJogos() {
    this.buscarJogosSubscription = this.siteService.buscarJogos(-99, -99)
      .subscribe((res: Jogo[]) => {
        this.jogos = res;
      })
  }

  idJogoChange(e) {
    this.buscarGaleria()
  }

  tipoChange(e) {
    this.buscarGaleria()
  }

  plataformaChange(e) {
    this.buscarGaleria()
  }

  changePage(e) {
    this.p = e;
    this.buscarGaleria();
  }

  open(index: number): void {
    this._lightbox.open(this.galeria, index);
  }

  close(): void {
    this._lightbox.close();
  }

  download(src: string) {
    window.open(src, '_blank');
  }
}
