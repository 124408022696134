<app-banner-interno [backgroundImage]="noticia.imagem">
</app-banner-interno>

<section class="noticia-interna">
    <div class="container">
        <div class="noticia-interna-wrapper">
            <ul class="social">
                <li class="social-item">
                    <a [href]="'http://www.facebook.com/sharer.php?u=' + shareUrl" class="social-item__link"
                        title="Facebook" target="_blank">
                        <i class="fab fa-facebook-square facebook-color"></i>
                    </a>
                </li>
                <li class="social-item">
                    <a [href]="'https://twitter.com/intent/tweet?url=' + shareUrl" class="social-item__link"
                        title="Twitter" target="_blank">
                        <i class="fab fa-twitter twitter-color"></i>
                    </a>
                </li>
                <li class="social-item">
                    <a class="social-item__link" title="Whatsapp"
                        [href]="sanitizer.bypassSecurityTrustResourceUrl('whatsapp://send?text=Se%20liga%20nessa%20publicação%20da%20Black%20Dragons!%20' + shareUrl)"
                        target="_blank">
                        <i class="fab fa-whatsapp whatsapp-color"></i>
                    </a>
                </li>
                <li class="social-item">
                    <a class="social-item__link" title="Link"
                        (click)="copyMessage('Se liga nessa publicação da Black Dragons! ' + shareUrl)">
                        <i class="fas fa-link"></i>
                    </a>
                </li>
            </ul>
            <div class="left">
                <div class="top" #top>
                    <ul class="noticia-interna-tags">
                        <li class="noticia-interna-tags__item">
                            <span>{{noticia.jogo?.nome}}</span>
                        </li>
                    </ul>
                    <h1 class="noticia-interna__title">
                        {{noticia.titulo}}
                    </h1>
                    <span class="noticia-interna__date">
                        Publicado por <b>{{noticia.autor ? noticia.autor : 'Black Dragons'}}</b> -
                        {{helper.moment(noticia.data).format('DD.MM.YYYY')}}
                    </span>
                </div>
                <div class="noticia-interna__description summernote" [innerHtml]="html"></div>
            </div>
            <div class="right" [style.marginTop]="(topElement.nativeElement.offsetHeight + 54) + 'px'">
            <h2 class="noticia-interna-related__title">ÚLTIMAS NOTÍCIAS</h2>
                <div class="noticia-interna-related-list">
                    <div class="blog-item blog-item--related" *ngFor="let n of noticias;">
                        <div class="blog-item__content">
                            <ul class="blog-item__tags">
                                <li>{{n.jogo?.nome}}</li>
                            </ul>
                            <h3 class="blog-item__title">{{n.titulo}}</h3>
                            <p class="blog-item__description">
                                {{n.descricaoSimples | slice:0:180}}{{n.descricaoSimples.length >= 1 ? '...' : ''}}
                            </p>
                            <a [routerLink]="['/noticias', n.slug]" class="btn btn-default btn-small"
                                title="continuar lendo">
                                CONTINUAR LENDO
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>