import { CadastroGeral } from './../../models/cadastro-geral.model';
import { Patrocinador } from './../../models/patrocinador.model';
import { SiteService } from './../../services/site.service';
import { ContatoGeral } from './../../models/contato-geral.model';
import { Component, OnInit, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input('cadastroGeral') cadastroGeral: CadastroGeral;
  @Input('contatoGeral') contatoGeral: ContatoGeral;

  patrocinadores: Patrocinador[] = []

  openMenu: boolean = false;
  active: boolean = false;

  @HostListener('window:scroll', [])
  handleScroll(): void {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= 50) {
      this.active = true;
    } else {
      this.active = false;
    }
  }

  constructor(
    public siteService: SiteService
  ) { }

  ngOnInit() {
    this.buscarPatrocinadores();
  }

  buscarPatrocinadores() {
    this.siteService.buscarPatrocinadores(-99, -99).subscribe((res: Patrocinador[]) => this.patrocinadores = res);
  }

}
