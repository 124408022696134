import { Cabecalho } from './../../models/cabecalho.model';
import { LoadingService } from './../../services/loading.service';
import { Jogo } from './../../models/jogo.model';
import { SiteService } from './../../services/site.service';
import { Component, OnInit } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { Noticia } from 'src/app/models/noticia.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss']
})
export class NoticiasComponent implements OnInit {

  noticias: Noticia[] = [];
  jogos: Jogo[] = [];

  buscarNoticiasSubscription: Subscription;

  cabecalho: Cabecalho = new Cabecalho();

  forkSubscription: Subscription;

  p: number = 1;
  totalPages: number;
  idJogo = '%';

  openFilter: boolean = false;

  constructor(
    public siteService: SiteService,
    public loadingService: LoadingService,
    public helper: HelperService
  ) { }

  ngOnInit() {

    if (localStorage.getItem('cabecalhos')) {
      this.cabecalho = JSON.parse(localStorage.getItem('cabecalhos'));
    }

    let buscarNoticias = this.siteService.buscarNoticias(this.p, 6, this.idJogo);
    let buscarJogos = this.siteService.buscarJogos(-99, -99);

    this.fork([buscarNoticias, buscarJogos]);
    this.helper.setCanonicalURL();
  }

  ngOnDestroy() {
    this.forkSubscription.unsubscribe();
  }

  fork(reqs) {
    this.loadingService.present();
    this.forkSubscription = forkJoin(reqs)
      .subscribe((res: any) => {
        this.noticias = res[0].noticias;
        this.jogos = res[1];
        this.totalPages = res[0].numeroPaginas * 6;
        this.loadingService.dismiss();
      });
  }

  buscarNoticias() {

    if (this.buscarNoticiasSubscription && !this.buscarNoticiasSubscription.closed) {
      this.buscarNoticiasSubscription.unsubscribe();
    }

    this.buscarNoticiasSubscription = this.siteService.buscarNoticias(this.p, 6, this.idJogo)
      .subscribe((res: any) => {
        this.noticias = res.noticias;
        this.totalPages = res.numeroPaginas * 6;
      });
  }

  changePage(event) {
    this.p = event;
    this.buscarNoticias();
  }

  idJogoChange(e) {
    this.buscarNoticias();
  }

}
