import { NotFoundComponent } from './pages/not-found/not-found.component';
import { NoticiasInternaComponent } from './pages/noticias-interna/noticias-interna.component';
import { AssistaComponent } from './pages/assista/assista.component';
import { ConfiguracoesComponent } from './pages/configuracoes/configuracoes.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { ComunidadeComponent } from './pages/comunidade/comunidade.component';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { PatrocinadoresComponent } from './pages/patrocinadores/patrocinadores.component';
import { TimesInternoComponent } from './pages/times-interno/times-interno.component';
import { TimesComponent } from './pages/times/times.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { HomeComponent } from './pages/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  { path: 'inicio', component: HomeComponent },
  { path: 'sobre', component: SobreComponent },
  { path: 'noticias', component: NoticiasComponent },
  { path: 'noticias/:slug', component: NoticiasInternaComponent },
  { path: ':slugJogo/:idJogo/times', component: TimesComponent },
  { path: ':slugJogo/:idJogo/times/:slug/:id', component: TimesInternoComponent },
  { path: 'patrocinadores', component: PatrocinadoresComponent },
  { path: 'comunidade', component: ComunidadeComponent },
  { path: 'agenda', component: AgendaComponent },
  { path: 'configuracoes', component: ConfiguracoesComponent },
  { path: 'assista', component: AssistaComponent },
  { path: 'contato', component: ContatoComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', redirectTo: '/not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
