<app-banner-interno [title]="title" [titleBold]="titleBold" [backgroundImage]="background">
</app-banner-interno>

<section class="internal-team">
    <div class="container">
        <div class="internal-team__image">
            <img [defaultImage]="'assets/image-placeholder.gif'" [lazyLoad]="time.imagemGrande"
                [errorImage]="'assets/image-error-placeholder.jpg'" [alt]="time.nome" [title]="time.nome"
                loading="lazy">
        </div>
        <div class="internal-team-content">
            <div class="internal-team-about">
                <h2 class="internal-team-about__title"><b>SOBRE</b> A EQUIPE</h2>
                <div class="internal-team-about__description" [innerHtml]="time.descricao"></div>
            </div>
            <div class="internal-team-stats">
                <h2 class="internal-team-stats__title"><b>STATS</b></h2>
                <div class="internal-team-stats__item">
                    <span>VITÓRIAS</span>
                    <strong>{{time.vitorias}}</strong>
                </div>
                <div class="internal-team-stats__item">
                    <span>DERROTAS</span>
                    <strong>{{time.derrotas}}</strong>
                </div>
                <div class="internal-team-stats__item">
                    <span>CAMPEONATOS</span>
                    <strong>{{time.campeonatos}}</strong>
                </div>
            </div>
        </div>
        <h2 class="internal-team__title">
            CONHEÇA <strong>NOSSOS ATLETAS</strong>
        </h2>
        <div class="athlete-list">
            <div class="athlete-item" *ngFor="let jogador of jogadores;">
                <div class="athlete-item__image">
                    <img [defaultImage]="'assets/image-placeholder.gif'" [lazyLoad]="helper.renderImage(jogador.foto, 220)"
                        [errorImage]="'assets/image-error-placeholder.jpg'" [alt]="jogador.nome" [title]="jogador.nome"
                        loading="lazy" width="110" height="230">
                </div>
                <div class="athlete-item__content">
                    <div class="top">
                        <div class="athlete-item__role" *ngIf="jogador.posicao">{{jogador.posicao}}</div>
                        <ul class="athlete-item__social">
                            <li *ngIf="jogador.linkFacebook">
                                <a [href]="jogador.linkFacebook" target="_blank" title="Facebook">
                                    <span>facebook</span>
                                    <i class="fab fa-facebook-square facebook-color"></i>
                                </a>
                            </li>
                            <li *ngIf="jogador.linkTwitter">
                                <a [href]="jogador.linkTwitter" target="_blank" title="Twitter">
                                    <span>twitter</span>
                                    <i class="fab fa-twitter twitter-color"></i>
                                </a>
                            </li>
                            <li *ngIf="jogador.linkInstagram">
                                <a [href]="jogador.linkInstagram" target="_blank" title="Instagram">
                                    <span>instagram</span>
                                    <i class="fab fa-instagram instagram-gradient"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <h3 class="athlete-item__name">{{jogador.nome}}</h3>
                    <span class="athlete-item__nick">"{{jogador.apelido}}"</span>
                    <a (click)="present(jogador)" class="btn btn-secondary btn-small btn-block">VER CONFIG E
                        STATS</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="modal-athlete" [class.open]="openModal">
    <button class="modal-athlete__close" (click)="openModal = !openModal">
        <i class="fas fa-times"></i>
    </button>
    <div class="modal-athlete-header">
        <div class="modal-athlete-header__avatar">
            <img [src]="helper.renderImage(jogador.foto, 416) || 'assets/image-error-placeholder.jpg'" [alt]="jogador.nome"
                [title]="jogador.nome" loading="lazy">
        </div>
        <div class="modal-athlete-header__content">
            <h2 class="modal-athlete-header__name">{{jogador.nome}}</h2>
            <ul class="modal-athlete-header__social">
                <li *ngIf="jogador.linkFacebook">
                    <a [href]="jogador.linkFacebook" target="_blank" title="Facebook">
                        <i class="fab fa-facebook-square facebook-color"></i>
                    </a>
                </li>
                <li *ngIf="jogador.linkTwitter">
                    <a [href]="jogador.linkTwitter" target="_blank" title="Twitter">
                        <i class="fab fa-twitter twitter-color"></i>
                    </a>
                </li>
                <li *ngIf="jogador.linkInstagram">
                    <a [href]="jogador.linkInstagram" target="_blank" title="Instagram">
                        <i class="fab fa-instagram instagram-gradient"></i>
                    </a>
                </li>
            </ul>
            <span class="modal-athlete-header__nick">{{jogador.apelido}}</span>
            <div class="modal-athlete-header__description">
                <p *ngIf="jogador.descricao">
                    {{jogador.descricao}}
                </p>
                <img [src]="jogo.icone" [alt]="jogo.nome" [title]="jogo.nome" loading="lazy">
            </div>
        </div>
    </div>
    <div class="modal-athlete-content" *ngIf="!!configuracao.equipamentos">
        <div class="modal-athlete-content__title">
            <h2>Configurações em jogo</h2>
            <hr>
        </div>
        <div class="list">
            <div class="col" *ngFor="let e of configuracao.equipamentos;">
                <h3>{{e.nome}}</h3>
                <ul>
                    <li *ngFor="let c of e.caracteristicas">
                        <span>{{c.nome}}</span>
                        <hr>
                        <strong>{{c.valor}}</strong>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<div class="modal-athlete-backdrop" [class.active]="openModal" (click)="openModal = !openModal"></div>