import { Cabecalho } from './models/cabecalho.model';
import { HelperService } from './services/helper.service';
import { AlertService } from './services/alert.service';
import { ContatoGeral } from './models/contato-geral.model';
import { GlobalService } from './services/global.service';
import { CadastroGeral } from './models/cadastro-geral.model';
import { SiteService } from './services/site.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  cadastroGeral: CadastroGeral = new CadastroGeral();
  contatoGeral: ContatoGeral = new ContatoGeral();

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    let keyPressed = event.keyCode;
    if (keyPressed === 27) {
      if (this.alertService.active) {
        this.alertService.dismiss();
      }
    }
  }

  constructor(
    public siteService: SiteService,
    public global: GlobalService,
    public alertService: AlertService,
    public meta: Meta,
    public title: Title,
    public helper: HelperService
  ) {

  }

  ngOnInit() {
    this.buscarCadastroGeral();
    this.buscarContatoGeral();
    this.buscarCabecalhos();
  }

  buscarCadastroGeral() {
    this.siteService.buscarCadastroGeral()
      .subscribe((res: CadastroGeral) => {
        this.cadastroGeral = res;
        localStorage.setItem('cadastro-geral', JSON.stringify(res));
        this.title.setTitle(res.titulo);
        this.meta.addTags([
          { name: 'date', content: this.helper.moment().format('YYYY-MM-DD'), scheme: 'YYYY-MM-DD' },
        ])
      })
  }

  buscarContatoGeral() {
    this.siteService.buscarContatoGeral()
      .subscribe((res: ContatoGeral) => {
        this.contatoGeral = res;
        localStorage.setItem('contato-geral', JSON.stringify(res));
      })
  }

  buscarCabecalhos() {
    this.siteService.buscarCabecalho()
      .subscribe((res: Cabecalho) => localStorage.setItem('cabecalhos', JSON.stringify(res)))
  }
}
