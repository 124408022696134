<app-banner-interno [title]="'Blog'" [titleBold]="'Black Dragons'"
    [backgroundImage]="cabecalho.imagemNoticia || 'assets/banner.png'">
</app-banner-interno>

<section class="noticias">
    <div class="container">
        <div class="noticias-destaques">
            <div class="title title-default">
                <h2 class="title__name">
                    <strong>Notícias</strong>
                </h2>
                <div class="filters">
                    <h3 class="filters__title">Filtros:&nbsp;&nbsp;</h3>
                    <div class="filters-list">
                        <div class="filters-item">
                            <input type="radio" [(ngModel)]="idJogo" (ngModelChange)="idJogoChange($event)"
                                name="filtrosPartidas" id="filtrosPartidasTodos" value="%">
                            <label for="filtrosPartidasTodos">Todos</label>
                        </div>
                        <div class="filters-item" *ngFor="let jogo of jogos; let i = index;"
                            [class.hidden]="i > 1 && !openFilter">
                            <input type="radio" [(ngModel)]="idJogo" (ngModelChange)="idJogoChange($event)"
                                name="filtrosPartidas" [id]="'filtrosPartidas' + jogo.id" [value]="jogo.id">
                            <label [for]="'filtrosPartidas' + jogo.id">{{jogo.nome}}</label>
                        </div>
                        <button class="btn btn-default btn-small btn-block hidden-desktop"
                            (click)="openFilter = !openFilter">mostrar
                            {{openFilter ? 'menos' : 'mais'}}</button>
                    </div>
                </div>
            </div>
            <div class="blog-list">
                <div class="blog-item"
                    *ngFor="let n of noticias | paginate: { itemsPerPage: 6, currentPage: p, totalItems: totalPages }; let i = index;">
                    <a [routerLink]="['/noticias', n.slug]" class="blog-item__image">
                        <img [defaultImage]="'assets/image-placeholder.gif'" [lazyLoad]="helper.renderImageHeight(n.imagem, 360)"
                            [errorImage]="'assets/image-error-placeholder.jpg'" [alt]="n.titulo" [title]="n.titulo"
                            loading="lazy">
                    </a>
                    <div class="blog-item__content">
                        <ul class="blog-item__tags">
                            <li>{{n.jogo?.nome}}</li>
                        </ul>
                        <h3 class="blog-item__title">{{n.titulo}}</h3>
                        <p class="blog-item__description">
                            {{n.descricaoSimples | slice:0:180}}{{n.descricaoSimples.length >= 1 ? '...' : ''}}
                        </p>
                        <a [routerLink]="['/noticias', n.slug]" class="btn btn-default btn-small"
                            title="continuar lendo">
                            CONTINUAR LENDO
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <pagination-controls (pageChange)="changePage($event)" class="pagination" maxSize="7" previousLabel=""
            nextLabel="">
        </pagination-controls>
    </div>
</section>