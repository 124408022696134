<app-banner-interno [title]="'Comunidade'" [titleBold]="''"
    [backgroundImage]="cabecalho.imagemComunidade || 'assets/banner.png'">
</app-banner-interno>

<section class="community">
    <div class="container">
        <div class="title title-default">
            <h2 class="title__name">
                <strong>Fotos</strong>
            </h2>
            <p class="title__content">
                Confira nossas<br />fotos oficiais
            </p>
        </div>
        <div class="community-filter">
            <div class="toggle">
                <div class="toggle-item">
                    <input type="radio" name="toggle-plataforma" [(ngModel)]="plataforma"
                        (ngModelChange)="plataformaChange($event)" id="toggle-plataforma-desktop" value="D" checked>
                    <label for="toggle-plataforma-desktop">DESKTOP</label>
                </div>
                <div class="toggle-item">
                    <input type="radio" name="toggle-plataforma" [(ngModel)]="plataforma"
                        (ngModelChange)="plataformaChange($event)" id="toggle-plataforma-mobile" value="M">
                    <label for="toggle-plataforma-mobile">MOBILE</label>
                </div>
            </div>
            <div class="toggle">
                <div class="toggle-item">
                    <input type="radio" name="toggle-comunidade" [(ngModel)]="tipo" (ngModelChange)="tipoChange($event)"
                        id="toggle-comunidade-fotos" value="F" checked>
                    <label for="toggle-comunidade-fotos">FOTOS</label>
                </div>
                <div class="toggle-item">
                    <input type="radio" name="toggle-comunidade" [(ngModel)]="tipo" (ngModelChange)="tipoChange($event)"
                        id="toggle-comunidade-wallpaper" value="W">
                    <label for="toggle-comunidade-wallpaper">WALLPAPERS</label>
                </div>
            </div>
            <div class="filters">
                <h4 class="filters__title">Filtros:&nbsp;&nbsp;</h4>
                <div class="filters-list">
                    <div class="filters-item">
                        <input type="radio" [(ngModel)]="idJogo" (ngModelChange)="idJogoChange($event)"
                            name="filtrosPartidas" id="filtrosPartidasTodos" value="%">
                        <label for="filtrosPartidasTodos">Todos</label>
                    </div>
                    <div class="filters-item" *ngFor="let jogo of jogos; let i = index;"
                        [class.hidden]="i > 1 && !openFilter">
                        <input type="radio" [(ngModel)]="idJogo" (ngModelChange)="idJogoChange($event)"
                            name="filtrosPartidas" [id]="'filtrosPartidas' + jogo.id" [value]="jogo.id">
                        <label [for]="'filtrosPartidas' + jogo.id">{{jogo.nome}}</label>
                    </div>
                </div>
                <button class="btn btn-default btn-small btn-block hidden-desktop"
                    (click)="openFilter = !openFilter">mostrar
                    {{openFilter ? 'menos' : 'mais'}}</button>
            </div>
        </div>
        <div class="community-wrapper">
            <div class="community-item"
                *ngFor="let g of galeria | paginate: { itemsPerPage: 12, currentPage: p, totalItems: totalPages }; let i = index;">
                <img [defaultImage]="'assets/image-placeholder.gif'" [lazyLoad]="g.thumb"
                    [errorImage]="'assets/image-error-placeholder.jpg'" [alt]="'galeria'+i" [title]="'galeria'+i"
                    loading="lazy" [@simpleFadeAnimation]="'in'" (click)="open(i)" height="172" width="340">
                <button class="community-item__download" (click)="download(g.src)">
                    <span>download</span>
                    <i class="fas fa-download"></i>
                </button>
            </div>
        </div>
        <pagination-controls (pageChange)="changePage($event)" class="pagination" maxSize="7" previousLabel=""
            nextLabel="">
        </pagination-controls>
    </div>
</section>