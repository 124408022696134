<app-banner-interno [title]="'Configurações'" [backgroundImage]="'assets/banner.png'">
</app-banner-interno>

<section class="configs">
    <div class="container">
        <div class="configs__title">
            <div class="title title-default title-dark">
                <p class="title__content">
                    Escolha um jogo para ver<br />as configurações dos nossos players
                </p>
            </div>
        </div>
    </div>
    <div class="box-teams-list">
        <div class="box-teams-item" *ngFor="let jogo of jogos; let i = index;">
            <div class="box-teams-item__content">
                <h2 class="box-teams-item__title">{{jogo.nome}}</h2>
                <a (click)="verJogo(jogo)" [title]="jogo.nome"
                    class="box-teams-item__button btn btn-default">VER EQUIPE</a>
            </div>
            <div class="box-teams-item__image">
                <img [src]="jogo.imagem" [alt]="jogo.nome" [title]="jogo.nome" loading="lazy">
            </div>
        </div>
    </div>

</section>