<section class="banner-interno">
    <img [src]="background_image" [alt]="title" [title]="title" loading="lazy" width="1920" height="500" class="banner-interno__image">
    <div class="container">
        <div class="banner-interno-wrapper">
            <h1 class="banner-interno__title" *ngIf="title">
                {{title}}
                <b>{{title_bold}}</b>
            </h1>
        </div>
    </div>
</section>