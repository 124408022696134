import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-banner-interno',
  templateUrl: './banner-interno.component.html',
  styleUrls: ['./banner-interno.component.scss']
})
export class BannerInternoComponent implements OnInit {

  @Input('title') title: string;
  @Input('titleBold') title_bold: string;
  @Input('backgroundImage') background_image: string;
  constructor() { }

  ngOnInit() {
  }

}
