import { Apoiador } from './../../models/apoiador.model';
import { Router } from '@angular/router';
import { HelperService } from './../../services/helper.service';
import { Partida } from './../../models/partida.model';
import { LoadingService } from './../../services/loading.service';
import { Patrocinador } from './../../models/patrocinador.model';
import { Produto } from './../../models/produto.model';
import { Sobre } from './../../models/sobre.model';
import { Jogo } from './../../models/jogo.model';
import { SiteService } from './../../services/site.service';
import { Banner } from './../../models/banner.model';
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, AfterContentChecked } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { Noticia } from 'src/app/models/noticia.model';
import { Subscription, forkJoin } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterContentChecked {

  @ViewChild('sobreImg', { static: true }) sobreImage: ElementRef;
  sobreImageHeight: number = 0;

  banners: Banner[] = [];
  jogos: Jogo[] = [];
  sobre: Sobre = new Sobre();
  noticias: Noticia[] = [];
  produtos: Produto[] = [];
  patrocinadores: Patrocinador[] = [];
  partidas: Partida[] = [];

  forkSubscription: Subscription;

  togglePartidas = '0';
  idJogo: any = '%';

  carouselBanner: NguCarouselConfig;

  openFilter: boolean = false;

  constructor(
    public siteService: SiteService,
    public loadingService: LoadingService,
    public helper: HelperService,
    public router: Router
  ) {
    this.carouselBanner = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 500,
      interval: {
        timing: 3000,
        initialDelay: 1000
      },
      point: {
        visible: true,
        hideOnSingleSlide: true
      },
      load: 1,
      loop: true,
      touch: false,
      easing: 'cubic-bezier(0, 0, 0.2, 1)'
    }
  }

  ngOnInit() {

    let buscarBanners = this.siteService.buscarBanners();
    let buscarJogos = this.siteService.buscarJogos(-99, -99);
    let buscarSobre = this.siteService.buscarSobre();
    let buscarProdutos = this.siteService.buscarProdutos(-99, -99);
    let buscarPartidas = this.siteService.buscarPartidasHome(1, 6, '%', this.helper.moment().format('YYYY-MM-DD'));
    let buscarDestaques = this.siteService.buscarDestaques(1, 2);
    let buscarPatrocinadores = this.siteService.buscarPatrocinadores(-99, -99);
    this.helper.setCanonicalURL();
    this.buscarHome([buscarBanners, buscarJogos, buscarSobre, buscarProdutos, buscarPartidas, buscarDestaques, buscarPatrocinadores]);
  }

  ngOnDestroy() {
    this.forkSubscription.unsubscribe();
  }

  ngAfterContentChecked() {
    this.sobreImageHeight = this.sobreImage.nativeElement.offsetHeight + 180;
  }

  buscarHome(reqs) {
    this.loadingService.present();
    this.forkSubscription = forkJoin(reqs)
      .subscribe((res: any) => {
        this.banners = res[0];
        this.jogos = res[1]
        this.sobre = res[2];
        this.produtos = res[3];
        this.partidas = res[4];
        this.noticias = res[5].noticias;
        this.patrocinadores = res[6];
        this.loadingService.dismiss();
      }, e => {
        this.loadingService.dismiss();
      })
  }

  buscarPartidas() {
    this.siteService.buscarPartidasHome(1, 6, this.idJogo, this.helper.moment().format('YYYY-MM-DD'))
      .subscribe((res: Partida[]) => this.partidas = res);
  }

  togglePartidasChange(e) {
    this.buscarPartidas();
  }

  idJogoChange(e) {
    this.buscarPartidas();
  }

  verJogo(jogo: Jogo) {
    this.loadingService.present();
    this.siteService.buscarTimes(1, 2, jogo.id.toString())
      .subscribe((res: any) => {
        if (res.length == 1) {
          this.router.navigate([`/${jogo.slug}/${jogo.id}/times/${res[0].slug}/${res[0].id}`]);
        } else {
          this.router.navigate([`${jogo.slug}/${jogo.id}/times`]);
        }
        this.loadingService.dismiss();
      })
  }

}
