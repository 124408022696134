export class Sobre {

    id: number;
    titulo: string = '';
    descricao1: string = '';
    imagemPequena: string = '';
    imagemGrande: string = '';
    missao: string = '';
    descricao2: string = '';
    caracteristica1: string = '';
    valorCaracteristica1: string = '';
    caracteristica2: string = '';
    valorCaracteristica2: string = '';
    caracteristica3: string = '';
    valorCaracteristica3: string = '';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}