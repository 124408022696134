import { AlertService } from './services/alert.service';
import { YoutubeService } from './services/youtube.service';
import { LoadingService } from './services/loading.service';
import { SiteService } from './services/site.service';
import { GlobalService } from './services/global.service';
import { ApiService } from './services/api.service';
import { HelperService } from './services/helper.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { NgModule } from '@angular/core';
// components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
// pages
import { HomeComponent } from './pages/home/home.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { BannerInternoComponent } from './components/banner-interno/banner-interno.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { TimesComponent } from './pages/times/times.component';
import { TimesInternoComponent } from './pages/times-interno/times-interno.component';
import { PatrocinadoresComponent } from './pages/patrocinadores/patrocinadores.component';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { ComunidadeComponent } from './pages/comunidade/comunidade.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { ConfiguracoesComponent } from './pages/configuracoes/configuracoes.component';
import { AssistaComponent } from './pages/assista/assista.component';

// imports
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NguCarouselModule } from '@ngu/carousel';
import { NgxPaginationModule } from 'ngx-pagination';
import { NoticiasInternaComponent } from './pages/noticias-interna/noticias-interna.component';
import { LightboxModule } from 'ngx-lightbox';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCalendarModule } from "ss-ngx-calendar";
import { ToastrModule } from 'ngx-toastr';
import { LazyLoadImageModule, scrollPreset } from 'ng-lazyload-image';
// providers
import { LoadingComponent } from './components/loading/loading.component';
import { AlertComponent } from './components/alert/alert.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SobreComponent,
    BannerInternoComponent,
    NoticiasComponent,
    TimesComponent,
    TimesInternoComponent,
    PatrocinadoresComponent,
    AgendaComponent,
    ComunidadeComponent,
    ContatoComponent,
    ConfiguracoesComponent,
    AssistaComponent,
    NoticiasInternaComponent,
    LoadingComponent,
    AlertComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NguCarouselModule,
    NgxPaginationModule,
    LightboxModule,
    NgxMaskModule.forRoot(),
    NgxCalendarModule,
    ToastrModule.forRoot(),
    LazyLoadImageModule.forRoot({
      // isBot: isBot,
      preset: scrollPreset
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    HelperService,
    ApiService,
    SiteService,
    GlobalService,
    LoadingService,
    YoutubeService,
    AlertService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
