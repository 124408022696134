import { Router } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { SiteService } from './../../services/site.service';
import { Subscription } from 'rxjs';
import { Jogo } from './../../models/jogo.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuracoes',
  templateUrl: './configuracoes.component.html',
  styleUrls: ['./configuracoes.component.scss']
})
export class ConfiguracoesComponent implements OnInit {

  jogos: Jogo[] = [];

  buscarJogosSubscription: Subscription;

  constructor(
    public siteService: SiteService,
    public loadingService: LoadingService,
    public router: Router
  ) { }

  ngOnInit() {
    this.buscarJogos();
  }

  ngOnDestroy() {
    this.buscarJogosSubscription.unsubscribe();
  }

  buscarJogos() {
    this.loadingService.present();
    this.buscarJogosSubscription = this.siteService.buscarJogos(-99, -99)
      .subscribe((res: Jogo[]) => {
        this.jogos = res;
        this.loadingService.dismiss();
      }, e => this.loadingService.dismiss());
  }

  verJogo(jogo: Jogo) {
    this.loadingService.present();
    this.siteService.buscarTimes(1, 2, jogo.id.toString())
      .subscribe((res: any) => {
        if (res.length == 1) {
          this.router.navigate([`/${jogo.slug}/${jogo.id}/times/${res[0].slug}/${res[0].id}`]);
        } else {
          this.router.navigate([`${jogo.slug}/${jogo.id}/times`]);
        }
        this.loadingService.dismiss();
      })
  }

}
