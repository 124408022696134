<app-banner-interno
  [title]="'Assista'"
  [backgroundImage]="cabecalho.imagemAssista || 'assets/banner.png'"
>
</app-banner-interno>

<section class="assista">
  <div class="assista-wrapper">
    <!-- <div class="col">
      <div class="assista__title">
        <h2>Lives</h2>
        <img
          src="assets/booyah.png"
          alt="Booyah"
          title="Booyah"
          loading="lazy"
        />
      </div>

      <div class="assista-list">
        <a
          class="assista-item assista-item--nimo"
          *ngFor="let l of lives; let i = index"
          [href]="l.link"
          [title]="l.titulo"
          target="_blank"
        >
          <div class="assista-item__image">
            <img
              [defaultImage]="'assets/image-placeholder.gif'"
              [lazyLoad]="l.imagem"
              [errorImage]="'assets/image-error-placeholder.jpg'"
              [alt]="l.streamer"
              [title]="l.streamer"
              loading="lazy"
            />
          </div>
          <div class="assista-item__content">
            <h3 class="assista-item__title">
              {{ l.titulo }}
            </h3>
            <span class="assista-item__date">{{ l.streamer }}</span>
          </div>
          <span class="assista-item__booyah"> BOOYAH! </span>
          <span class="assista-item__live" *ngIf="l.aoVivo === 'S'">
            AO VIVO
          </span>
        </a>
      </div>
    </div> -->
    <div class="col" *ngIf="videos.length || videos2.length">
      <div class="row">
        <div class="container">
          <div class="assista__title">
            <h2>Vídeos</h2>
            <img
              src="assets/icon/youtube.png"
              alt="Youtube"
              title="Youtube"
              loading="lazy"
            />
          </div>
          <h3 class="row-title">Black Dragons e-Sports</h3>
          <div class="assista-list">
            <a
              class="assista-item assista-item--youtube"
              *ngFor="let v of videos; let i = index"
              (click)="openModal(v.contentDetails.upload.videoId)"
            >
              <div class="assista-item__image">
                <img
                  [src]="v.snippet.thumbnails.high.url"
                  [alt]="v.snippet.title"
                  [title]="v.snippet.title"
                  loading="lazy"
                />
                <div class="assista-item-overlay">
                  <span class="assista-item-overlay__button">ASSISTIR</span>
                </div>
              </div>
              <div class="assista-item__content">
                <h3 class="assista-item__title">
                  {{ v.snippet?.title }}
                </h3>
                <span class="assista-item__date">{{
                  helper.moment(v.snippet?.publishedAt).calendar()
                }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="container">
          <h3 class="row-title">BD Mobile</h3>
          <div class="assista-list">
            <a
              class="assista-item assista-item--youtube"
              *ngFor="let v of videos2; let i = index"
              (click)="openModal(v.contentDetails.upload.videoId)"
            >
              <div class="assista-item__image">
                <img
                  [src]="v.snippet.thumbnails.high.url"
                  [alt]="v.snippet.title"
                  [title]="v.snippet.title"
                  loading="lazy"
                />
                <div class="assista-item-overlay">
                  <span class="assista-item-overlay__button">ASSISTIR</span>
                </div>
              </div>
              <div class="assista-item__content">
                <h3 class="assista-item__title">
                  {{ v.snippet?.title }}
                </h3>
                <span class="assista-item__date">{{
                  helper.moment(v.snippet?.publishedAt).calendar()
                }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="video-modal" [class.active]="videoModalOpen">
  <iframe
    [src]="videoModalUrl"
    frameborder="0"
    allow="autoplay; fullscreen"
    *ngIf="videoModalUrl"
  ></iframe>
</section>
<div
  class="video-modal-backdrop"
  [class.active]="videoModalOpen"
  (click)="dismissModal()"
></div>
