import { Cabecalho } from "./../../models/cabecalho.model";
import { ContatoGeral } from "./../../models/contato-geral.model";
import { AlertService } from "./../../services/alert.service";
import { Mensagem } from "./../../models/mensagem.model";
import { HelperService } from "./../../services/helper.service";
import { NgForm } from "@angular/forms";
import { LoadingService } from "./../../services/loading.service";
import { SiteService } from "./../../services/site.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-contato",
  templateUrl: "./contato.component.html",
  styleUrls: ["./contato.component.scss"],
})
export class ContatoComponent implements OnInit {
  mensagem: Mensagem = new Mensagem();

  phoneMask: string = "(00) 0000-00009";

  contatoGeral: ContatoGeral = new ContatoGeral();
  cabecalho: Cabecalho = new Cabecalho();

  constructor(
    public siteService: SiteService,
    public loadingService: LoadingService,
    public alertService: AlertService,
    public helper: HelperService
  ) {}

  ngOnInit() {
    if (localStorage.getItem("cabecalhos")) {
      this.contatoGeral = JSON.parse(localStorage.getItem("contato-geral"));
      this.cabecalho = JSON.parse(localStorage.getItem("cabecalhos"));
    }
    this.helper.setCanonicalURL();
  }

  enviarMensagem(f: NgForm) {
    console.log(f.invalid);
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loadingService.present();

    this.siteService.inserirContatoMensagem(this.mensagem).subscribe(
      (res: any) => {
        this.alertService.present(
          "Mensagem enviada",
          "Em breve entraremos em contato."
        );
        f.reset();
        this.loadingService.dismiss();
      },
      (e) => {
        this.alertService.present(
          "Não foi possível enviar a mensagem!",
          "Desculpe, mas não foi possível enviar a mensagem. Tente novamente outra hora."
        );
        this.loadingService.dismiss();
      }
    );
  }

  changePhoneMask(event) {
    let val = event;
    if (!val) {
      return;
    }
    if (val.replace(/\D/g, "").substring(0, 4) == "0800") {
      this.phoneMask = "0000 000 0000";
    } else {
      if (val.replace(/\D/g, "").length === 11) {
        this.phoneMask = "(00) 0 0000-0000";
      } else {
        this.phoneMask = "(00) 0000-00009";
      }
    }
  }
}
