export class Cabecalho {
    
    id: number;
    imagemSobre: string = '';
    imagemNoticia: string = '';
    imagemNossosTimes: string = '';
    imagemPatrocinadores: string = '';
    imagemAgenda: string = '';
    imagemAssista: string = '';
    imagemComunidade: string = '';
    imagemContato: string = '';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}