import { Equipamento } from './equipamento.model';
import { Jogo } from './jogo.model';
export class ConfiguracaoJogo {

    id: number;
    jogo: Jogo = new Jogo();
    equipamentos: Equipamento[] = [];

    constructor(obj?) {
        Object.assign(this);
    }
}