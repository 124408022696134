import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {

  apiUrl: string = 'https://www.googleapis.com/youtube/v3'
  apiKey: string = 'AIzaSyDSx4dYefZ7Wjk2cFKJqzv0kc1MvoVr6Qg';

  constructor(
    public http: HttpClient
  ) { }

  getActivities(channelId: string, maxResults: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/search?part=snippet,id&channelId=${channelId}&order=date&key=${this.apiKey}&maxResults=${maxResults}`);
  }
}
