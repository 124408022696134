<app-banner-interno [title]="'Nossos'" [titleBold]="'Patrocinadores'"
    [backgroundImage]="cabecalho.imagemPatrocinadores || 'assets/banner.png'">
</app-banner-interno>

<section class="sponsors">
    <div class="sponsors-wrapper">
        <div class="sponsors-item" *ngFor="let p of patrocinadores;">
            <img class="sponsors-item__background" [defaultImage]="'assets/image-placeholder.gif'"
                [lazyLoad]="p.background" [errorImage]="'assets/image-error-placeholder.jpg'" [alt]="p.nome"
                [title]="p.nome" loading="lazy" height="400" width="1920">
            <div class="sponsors-item__image">
                <img [src]="p.imagem" [alt]="p.nome" [title]="p.nome">
            </div>
            <div class="sponsors-item__content">
                <p [innerHtml]="p.descricao"></p>
                <a [href]="p.linkSite" target="_blank" class="btn btn-default">visitar site</a>
            </div>
        </div>
    </div>
    <div class="supporters">
        <div class="container">
            <div class="supporters-wrapper">
                <h2 class="supporters__title">APOIADORES</h2>
                <div class="supporters-list">
                    <a *ngFor="let a of apoiadores" [href]="a.linkSite" target="_blank" [title]="a.nome"
                        class="supporters-item">
                        <img [src]="a.imagem" [alt]="a.nome" [title]="a.nome" loading="lazy">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>