import { Jogo } from './jogo.model';
export class Time {

    id: number;
    descricao: string = '';
    situacao: string = 'A';
    imagemGrande: string = '';
    imagemPequena: string = '';
    vitorias: string = '';
    derrotas: string = '';
    campeonatos: string = '';
    order: number = 0;
    jogo: Jogo = new Jogo();
    slug: string = '';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}