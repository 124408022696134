export class Jogo {

    id: number;
    nome: string;
    descricao: string;
    situacao: string = 'A';
    imagem: string = '';
    icone: string = '';
    slug: string = '';

    constructor(obj?) {
        Object.assign(this);
    }
}