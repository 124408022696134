import { CadastroGeral } from './../../models/cadastro-geral.model';
import { ContatoGeral } from './../../models/contato-geral.model';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input('cadastroGeral') cadastroGeral: CadastroGeral;
  @Input('contatoGeral') contatoGeral: ContatoGeral;

  constructor(
    @Inject(DOCUMENT) public document: Document
  ) {
  }

  ngOnInit() {
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

}
