import { CadastroGeral } from './../../models/cadastro-geral.model';
import { DOCUMENT } from '@angular/common';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { SiteService } from './../../services/site.service';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DomSanitizer, Meta, Title, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Noticia } from 'src/app/models/noticia.model';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-noticias-interna',
  templateUrl: './noticias-interna.component.html',
  styleUrls: ['./noticias-interna.component.scss'],
})
export class NoticiasInternaComponent implements OnInit {

  @ViewChild('top', { static: true }) topElement: ElementRef;
  shareUrl: string = 'https://blackdragons.com.br';

  noticia: Noticia = new Noticia();
  noticias: Noticia[] = [];

  html: SafeHtml;

  buscarNoticaSubscription: Subscription;

  cadastroGeral: CadastroGeral = new CadastroGeral();

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public sanitizer: DomSanitizer,
    public router: Router,
    public route: ActivatedRoute,
    public siteService: SiteService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public toastr: ToastrService,
    public meta: Meta,
    public title: Title,
  ) { }

  ngOnInit() {
    this.shareUrl += this.router.url;
    this.route.params.subscribe(param => this.buscarNoticia(param.slug));
    this.helper.setCanonicalURL();
    this.cadastroGeral = JSON.parse(localStorage.getItem('cadastro-geral'));
  }

  ngOnDestroy() {
    this.updateTags(this.cadastroGeral, false);
    this.buscarNoticaSubscription.unsubscribe();
  }

  buscarNoticia(slug: string) {
    this.loadingService.present();
    this.buscarNoticaSubscription = this.siteService.buscarNoticia(slug)
      .subscribe((res: Noticia) => {
        this.noticia = res;
        this.html = this.sanitizer.bypassSecurityTrustHtml(res.descricaoCompleta);
        // seo
        this.title.setTitle(res.titulo);
        this.updateTags(res, true);
        this.siteService.buscarUltimasNoticias(1, 2, res.id)
          .subscribe((res: any) => {
            this.noticias = res.noticias;
            this.loadingService.dismiss();
          }, e => this.loadingService.dismiss());
      }, e => this.loadingService.dismiss());
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.info('Link copiado para sua área de transferência', '', {
      onActivateTick: true,
      progressBar: true,
      timeOut: 3000
    });
  }

  updateTags(res: any, isArticle: boolean) {
    this.title.setTitle(res.titulo);
    this.meta.updateTag({ name: 'title', content: res.titulo });
    this.meta.updateTag({ name: 'subject', content: res.titulo });
    this.meta.updateTag({ name: 'description', content: res.descricaoSimples });
    this.meta.updateTag({ name: 'url', content: this.shareUrl });
    this.meta.updateTag({ name: 'image', content: res.imagem });
    this.meta.updateTag({ property: 'og:title', content: res.titulo });
    this.meta.updateTag({ property: 'og:description', content: res.descricaoSimples });
    this.meta.updateTag({ property: 'og:url', content: this.shareUrl });
    this.meta.updateTag({ property: 'og:image', content: res.imagem });
    this.meta.updateTag({ property: 'og:image:secure_url', content: res.imagem });
    this.meta.updateTag({ property: 'og:image:alt', content: res.titulo });
    this.meta.updateTag({ name: 'twitter:title', content: res.titulo });
    this.meta.updateTag({ name: 'twitter:description', content: res.descricaoSimples });
    this.meta.updateTag({ name: 'twitter:url', content: this.shareUrl });
    this.meta.updateTag({ name: 'twitter:image', content: res.imagem });
    this.meta.updateTag({ name: 'twitter:image:src', content: res.imagem });
    this.meta.updateTag({ name: 'twitter:creator', content: res.autor });

    if (isArticle) {
      this.meta.addTags([
        { property: "article:tag", content: "alt tag" },
        { property: "article:tag", content: "canonical tag" },
        { property: "article:tag", content: "header tag" },
        { property: "article:tag", content: "html" },
        { property: "article:tag", content: "html tags" },
        { property: "article:tag", content: "meta description" },
        { property: "article:tag", content: "meta tags" },
        { property: "article:tag", content: "open graph" },
        { property: "article:tag", content: "responsive design meta tag" },
        { property: "article:tag", content: "robots meta tag" },
        { property: "article:tag", content: "seo" },
        { property: "article:tag", content: "title tag" },
        { property: "article:tag", content: "twitter card" },
        { property: "article:tag", content: "twitter card" },
        { property: "article:section", content: res.titulo },
        { property: "article:published_time", content: res.data.toString() },
        { property: "article:modified_time", content: res.data.toString() },
      ])
    } else {
      this.meta.removeTag("property='article:tag'");
      this.meta.removeTag("property='article:section'");
      this.meta.removeTag("property='article:published_time'");
      this.meta.removeTag("property='article:modified_time'");
    }
  }

}
