import { HelperService } from "./../../services/helper.service";
import { Cabecalho } from "./../../models/cabecalho.model";
import { LoadingService } from "./../../services/loading.service";
import { LinhaDoTempo } from "./../../models/linha-do-tempo.model";
import { Equipe } from "./../../models/equipe.model";
import { SiteService } from "./../../services/site.service";
import { Subscription, forkJoin } from "rxjs";
import { Sobre } from "./../../models/sobre.model";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-sobre",
  templateUrl: "./sobre.component.html",
  styleUrls: ["./sobre.component.scss"],
})
export class SobreComponent implements OnInit {
  equipe: Equipe[] = [];
  linhasDoTempo: LinhaDoTempo[] = [];
  sobre: Sobre = new Sobre();

  cabecalho: Cabecalho = new Cabecalho();

  forkSubscription: Subscription;

  constructor(
    public siteService: SiteService,
    public loadingService: LoadingService,
    public helper: HelperService
  ) {}

  ngOnInit() {
    if (localStorage.getItem("cabecalhos")) {
      this.cabecalho = JSON.parse(localStorage.getItem("cabecalhos"));
    }
    let buscarSobre = this.siteService.buscarSobre();
    let buscarEquipe = this.siteService.buscarEquipe(-99, -99);
    let buscarLinhasDoTempo = this.siteService.buscarLinhasDoTempo(-99, -99);
    this.helper.setCanonicalURL();
    this.buscarSobre([buscarSobre, buscarEquipe, buscarLinhasDoTempo]);
  }

  ngOnDestroy() {
    this.forkSubscription.unsubscribe();
  }

  buscarSobre(reqs) {
    this.loadingService.present();
    this.forkSubscription = forkJoin(reqs).subscribe(
      (res: any) => {
        this.sobre = res[0];
        this.equipe = res[1];
        this.linhasDoTempo = res[2];
        this.loadingService.dismiss();
      },
      (e) => this.loadingService.dismiss()
    );
  }
}
