import { Cabecalho } from './../../models/cabecalho.model';
import { DOCUMENT } from '@angular/common';
import { Jogo } from './../../models/jogo.model';
import { LoadingService } from './../../services/loading.service';
import { SiteService } from './../../services/site.service';
import { Subscription, forkJoin } from 'rxjs';
import { Partida } from './../../models/partida.model';
import { HelperService } from './../../services/helper.service';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, AfterContentInit, Inject } from '@angular/core';
import { NgxCalendarComponent } from 'ss-ngx-calendar';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit, AfterViewInit, AfterContentInit {

  @ViewChild("calendar") calendar: NgxCalendarComponent;

  calendarOptions = {};
  calendarEvents = [];
  calendarValue = null;
  calendarFilter = '%';
  calendarMonth: string = '';
  calendarYear: string = '';
  calendarDate: string = '';
  calendario: Partida[] = [];
  partidas: Partida[] = [];
  jogos: Jogo[] = [];

  cabecalho: Cabecalho = new Cabecalho();

  forkSubscription: Subscription;

  togglePartidas = '0';
  idJogo: any = '%';

  openFilter: boolean = false;
  totalCalendarItems: number;

  p: number = 1;

  page: number = 1;
  itemsPerPage: number = 8;
  noMore: boolean = false;

  openedCalendarEvents: boolean = false;

  buscarCalendarioSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public helper: HelperService,
    public siteService: SiteService,
    public loadingService: LoadingService,
    public el: ElementRef,
  ) {
    this.calendarYear = this.helper.moment().format('YYYY');
    this.calendarMonth = this.helper.moment().format('MMMM');
    this.calendarDate = `${this.calendarYear}-${this.helper.moment(this.calendarMonth, 'MMMM').format('MM')}-01`;
  }

  ngOnInit() {

    if (localStorage.getItem('cabecalhos')) {
      this.cabecalho = JSON.parse(localStorage.getItem('cabecalhos'));
    }

    this.calendarEvents = [];

    let buscarJogos = this.siteService.buscarJogos(-99, -99);
    let buscarPartidas = this.siteService.buscarPartidas(this.page, this.itemsPerPage, this.idJogo, this.calendarDate, this.validaPegaAnteriores());

    this.buscarHome([buscarJogos, buscarPartidas])
    this.helper.setCanonicalURL();
  }

  ngOnDestroy() {
    this.forkSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.calendar.calendar.data.date = this.helper.moment();
    this.calendar.setView(true);
    this.onChooseDate(this.helper.moment());
  }

  ngAfterContentInit() {
    this.updateCalendar();
  }

  openCalendarEvents() {
    this.openedCalendarEvents = !this.openedCalendarEvents;
  }

  onChooseDate(date: any) {
    this.calendarValue = date;
  }

  onChangeDate(e) {
    this.updateCalendar();
  }

  updateCalendar() {
    this.page = 1;
    this.noMore = false;
    setTimeout(() => {
      let count = 6;
      var list = document.querySelectorAll('.c-calendar-month-item');
      this.calendarMonth = document.querySelectorAll('.c-calendar-month-header').item(0).textContent.split(' ')[0];
      this.calendarYear = document.querySelectorAll('.c-calendar-month-header').item(0).textContent.split(' ')[1];
      this.calendarDate = `${this.calendarYear}-${this.helper.moment(this.calendarMonth, 'MMMM').format('MM')}-01`;
      this.calendar.calendar.data.rows.forEach(row => {
        row.columns.forEach(col => {
          count++;
          if (this.helper.moment(col.date).format('MMMM') !== this.calendarMonth) {
            list[count].className += ' last-month';
          }
        })
      });
      this.buscarPartidas();
      this.buscarCalendario();
    }, 50);
  }

  buscarHome(reqs) {
    this.loadingService.present();
    this.forkSubscription = forkJoin(reqs)
      .subscribe((res: any) => {
        this.jogos = res[0];
        this.partidas = res[1];
        this.calendarEvents = res[1].map(c => this.helper.moment(c.data).format('YYYY-MM-DD'));
        this.loadingService.dismiss();
      }, e => {
        this.loadingService.dismiss();
      })
  }

  buscarPartidas() {
    this.siteService.buscarPartidas(-99, -99, this.idJogo, this.calendarDate, this.validaPegaAnteriores())
      .subscribe((res: Partida[]) => {
        this.partidas = res;
        this.calendarEvents = res.map(c => this.helper.moment(c.data).format('YYYY-MM-DD'));
      });
  }

  buscarCalendario() {
    this.siteService.buscarPartidas(this.page, this.itemsPerPage, this.idJogo, this.calendarDate, this.validaPegaAnteriores())
      .subscribe((res: Partida[]) => {
        if (res.length < this.itemsPerPage) {
          this.noMore = true;
        }
        this.calendario = this.page == 1 ? res : this.calendario.concat(res);
      });
  }

  togglePartidasChange(e) {
    this.page = 1;
    this.noMore = false;
    this.buscarPartidas();
    this.buscarCalendario();
  }

  idJogoChange(e) {
    this.page = 1;
    this.noMore = false;
    this.buscarPartidas();
    this.buscarCalendario();
  }

  loadMore() {
    this.page++;
    this.buscarCalendario();
  }

  changePage(e) {
    this.p = e;
  }

  validaPegaAnteriores(): boolean {
    const pegaAnteriores = this.togglePartidas == '1' ? true : false;
    console.log(this.calendarMonth, this.helper.moment().format('MMMM'));
    return this.calendarMonth == this.helper.moment().format('MMMM') ? pegaAnteriores : true;
  }
}
