<app-banner-interno [title]="'Sobre a'" [titleBold]="'Black Dragons'"
    [backgroundImage]="cabecalho.imagemSobre || 'assets/banner.png'">
</app-banner-interno>

<section class="sobre">
    <div class="container">
        <div class="sobre__description" [innerHtml]="sobre.descricao1"></div>
    </div>
    <!-- <div class="sobre__image">
        <img [src]="sobre.imagemGrande" alt="Sobre" title="Sobre" loading="lazy" width="1920" height="400">
    </div> -->
    <div class="timeline">
        <div class="timeline-box">
            <div class="timeline__title">
                <h3>LINHA DO TEMPO</h3>
                <hr>
            </div>
            <div class="timeline-list">
                <div class="timeline-item" *ngFor="let linha of linhasDoTempo;">
                    <h4 class="timeline-item__title">
                        {{linha.ano.substr(0, 2)}}<b>{{linha.ano.substr(-2)}}</b>
                    </h4>
                    <p class="timeline-description">
                        {{linha.descricao}}
                    </p>
                </div>
            </div>
        </div>
        <!-- <div class="container">
            <div class="timeline__description" [innerHtml]="sobre.descricao2"></div>
        </div> -->
    </div>

    <div class="container">
        <h2 class="sobre__title" [innerHtml]="sobre.missao"></h2>
        <div class="sobre-stats">
            <div class="sobre-stats__title">
                <img src="assets/icon/team.svg" alt="Team" title="Somos">
                <h3>SOMOS</h3>
            </div>
            <div class="sobre-stats__list">
                <div class="sobre-stats__item">
                    <strong>{{sobre.valorCaracteristica1}}</strong>
                    <span>{{sobre.caracteristica1}}</span>
                </div>
                <div class="sobre-stats__item">
                    <strong>{{sobre.valorCaracteristica2}}</strong>
                    <span>{{sobre.caracteristica2}}</span>
                </div>
                <div class="sobre-stats__item">
                    <strong>{{sobre.valorCaracteristica3}}</strong>
                    <span>{{sobre.caracteristica3}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="staff">
        <div class="container">
            <h3 class="staff__title">
                Equipe <b>Black Dragons</b>
            </h3>
            <div class="staff-list">
                <div class="staff-item" *ngFor="let e of equipe; let i = index;">
                    <div class="staff-item__image">
                        <img [defaultImage]="'assets/image-placeholder.gif'" [lazyLoad]="e.imagem"
                            [errorImage]="'assets/image-error-placeholder.jpg'" [alt]="e.nome" [title]="e.nome"
                            loading="lazy" width="130" height="192">
                    </div>
                    <div class="staff-item__content">
                        <h3 class="staff-item__name">
                            {{e.nome}}
                        </h3>
                        <span class="staff-item__occupation">{{e.cargo}}</span>
                        <a [href]="'mailto:' + e.email" class="staff-item__email">{{e.email}}</a>
                        <ul class="staff-item__social">
                            <li *ngIf="e.linkFacebook">
                                <a [href]="e.linkFacebook" target="_blank" title="Facebook">
                                    <i class="fab fa-facebook-square facebook-color"></i>
                                </a>
                            </li>
                            <li *ngIf="e.linkTwitter">
                                <a [href]="e.linkTwitter" target="_blank" title="Twitter">
                                    <i class="fab fa-twitter twitter-color"></i>
                                </a>
                            </li>
                            <li *ngIf="e.linkInstagram">
                                <a [href]="e.linkInstagram" target="_blank" title="Instagram">
                                    <i class="fab fa-instagram instagram-gradient"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>