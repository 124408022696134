export class ContatoGeral {
    id: number;
    latitude: number;
    longitude: number;
    cep: string;
    rua: string;
    estado: string;
    cidade: string;
    numeroEndereco: string;
    complemento: string = '';
    linkInstagram?: string = '';
    linkLinkedin?: string = '';
    linkFacebook?: string = '';
    linkAppStore?: string = '';
    linkPlayStore?: string = '';
    email: string = 'contato@blackdragons.com.br';
    emailsAdicionais?: string = '';
    telefone: string;
    telefonesAdicionais?: string = '';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}