<app-banner-interno [title]="'Nossa'" [titleBold]="'Agenda'"
    [backgroundImage]="cabecalho.imagemAgenda || 'assets/banner.png'">
</app-banner-interno>

<section class="calendar">
    <div class="container">
        <div class="title title-default">
            <h2 class="title__name">
                <strong>Calendário</strong>
            </h2>
            <p class="title__content">
                Fique ligado nas<br />próximas partidas
            </p>
        </div>
        <div class="calendar-filter">
            <div class="filters">
                <div class="toggle"
                    *ngIf="calendarMonth == helper.moment().format('MMMM') && calendarYear == helper.moment().format('YYYY')">
                    <div class="toggle-item">
                        <input type="radio" name="toggle-partidas" [(ngModel)]="togglePartidas"
                            (ngModelChange)="togglePartidasChange($event)" id="toggle-partidas-recentes" value="1"
                            checked>
                        <label for="toggle-partidas-recentes">RECENTES</label>
                    </div>
                    <div class="toggle-item">
                        <input type="radio" name="toggle-partidas" [(ngModel)]="togglePartidas"
                            (ngModelChange)="togglePartidasChange($event)" id="toggle-partidas-proximas" value="0">
                        <label for="toggle-partidas-proximas">PRÓXIMAS</label>
                    </div>
                </div>
                <h3 class="filters__title">Filtros:&nbsp;&nbsp;</h3>
                <div class="filters-list">
                    <div class="filters-item">
                        <input type="radio" [(ngModel)]="idJogo" (ngModelChange)="idJogoChange($event)" name="filtros"
                            id="todos" value="%">
                        <label for="todos">Todos</label>
                    </div>
                    <div class="filters-item" *ngFor="let jogo of jogos; let i = index;"
                        [class.hidden]="i > 1 && !openFilter">
                        <input type="radio" name="filtros" [(ngModel)]="idJogo" (ngModelChange)="idJogoChange($event)"
                            [id]="'filtros' + jogo.id" [value]="jogo.id">
                        <label [for]="'filtros' + jogo.id">{{jogo.nome}}</label>
                    </div>
                </div>
                <button class="btn btn-default btn-small btn-block hidden-desktop"
                    (click)="openFilter = !openFilter">mostrar
                    {{openFilter ? 'menos' : 'mais'}}</button>
            </div>
        </div>
        <div class="calendar-content">
            <div class="col">
                <ngx-calendar #calendar (onChangeDate)="onChangeDate($event)" (onChooseDate)="onChooseDate($event)"
                    [options]="calendarOptions" [events]="calendarEvents">
                </ngx-calendar>
            </div>
            <div class="col">
                <ul class="calendar-events" *ngIf="calendario.length">
                    <ng-container *ngFor="let c of calendario; let i = index;">
                        <li class="calendar-events__item">
                            <div class="calendar-events__day">{{helper.moment(c.data).format('DD')}}</div>
                            <strong>{{c.jogo?.nome}}</strong>&nbsp;
                            <span>- {{c.liga?.nome}}</span>
                        </li>
                    </ng-container>
                </ul>
                <p *ngIf="!calendario.length">
                    Oops... Parece que não temos nenhum partida<br />no mês de <b>{{calendarMonth}}</b>
                </p>
                <button class="btn btn-default btn-small calendar-events__button" (click)="loadMore()" *ngIf="!noMore">
                    mostrar mais
                </button>
            </div>
        </div>
    </div>

</section>

<section class="matches" *ngIf="partidas.length">
    <div class="container">
        <div class="title title-default">
            <h2 class="title__name">
                <strong>Partidas</strong>
            </h2>
            <p class="title__content">
                Fique ligado nas<br />próximas partidas
            </p>
        </div>
        <div class="matches-wrapper">
            <div class="matches-item" [title]="partida.liga"
                *ngFor="let partida of partidas | paginate: { itemsPerPage: 8, currentPage: p, totalItems: partidas.length }; let i = index"
                [class.matches-item--royale]="partida.jogo.battleRoyale === 'S'">
                <a class="matches-item__live" title="live" [href]="partida.link" target="_blank"
                    *ngIf="partida.status === 'A'">AO VIVO</a>
                <a class="matches-item__live matches-item__live--primary" title="live" [href]="partida.link"
                    target="_blank" *ngIf="partida.status === 'V'">VEJA COMO FOI</a>
                <div class="matches-item__icon">
                    <img [src]="partida.liga.imagem" [alt]="partida.liga.nome" [title]="partida.liga.nome"
                        loading="lazy">
                </div>
                <div class="matches-item__logo">
                    <img src="assets/logo.svg" alt="Black Dragons" title="Black Dragons" loading="lazy">
                    <span>Black Dragons</span>
                </div>
                <div class="matches-item__content">
                    <h3 class="matches-item__game">{{partida.jogo?.nome}}</h3>
                    <h2 class="matches-item__score" [class.empty]="partida.placarBlackDragons == null"
                        [class.wo]="partida.resultado === 'WD' || partida.resultado === 'W'">
                        <ng-container *ngIf="partida.resultado === 'W'">
                            W.O Vitória
                        </ng-container>
                        <ng-container *ngIf="partida.resultado === 'WD'">
                            W.O Derrota
                        </ng-container>
                        <ng-container *ngIf="partida.resultado !== 'W' && partida.resultado !== 'WD'">
                            <ng-container *ngIf="partida.jogo.battleRoyale === 'N'">
                                {{partida.placarBlackDragons !== null ? partida.placarBlackDragons + '-' + partida.placarAdversario : 'vs'}}
                            </ng-container>
                            <ng-container *ngIf="partida.jogo.battleRoyale === 'S'">
                                {{partida.placarBlackDragons !== null ? partida.placarBlackDragons + 'º' : 'EM BREVE'}}
                            </ng-container>
                        </ng-container>
                    </h2>
                    <span class="matches-item__league">{{partida.liga?.nome}}</span>
                    <small class="matches-item__date">
                        {{helper.moment(partida.data).format('DD.MM.YYYY')}}
                        <ng-container *ngIf="partida.hora">
                            {{helper.moment(partida.hora).format('HH:mm')}}
                        </ng-container>
                    </small>
                </div>
                <div class="matches-item__logo">
                    <ng-container *ngIf="partida.jogo.battleRoyale === 'N'">
                        <img [src]="partida.imagemEscudoAdversario || 'assets/brazao-placeholder.png'"
                            [alt]="partida.liga" [title]="partida.liga" loading="lazy">
                        <span>{{partida.nomeAdversario}}</span>
                    </ng-container>
                    <p *ngIf="partida.jogo.battleRoyale === 'S'">
                        {{partida.descricaoBattleRoyale}}
                    </p>
                </div>
            </div>
        </div>

        <pagination-controls (pageChange)="changePage($event)" class="pagination" maxSize="7" previousLabel=""
            nextLabel="">
        </pagination-controls>
    </div>
</section>