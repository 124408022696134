import { Cabecalho } from './../../models/cabecalho.model';
import { Live } from './../../models/live.model';
import { SiteService } from './../../services/site.service';
import { Subscription, forkJoin } from 'rxjs';
import { LoadingService } from './../../services/loading.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HelperService } from './../../services/helper.service';
import { YoutubeService } from './../../services/youtube.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assista',
  templateUrl: './assista.component.html',
  styleUrls: ['./assista.component.scss']
})
export class AssistaComponent implements OnInit {

  videos: any[] = []
  videos2: any[] = []
  videoModalUrl: SafeUrl;
  videoModalOpen: boolean = false;

  lives: Live[] = [];

  cabecalho: Cabecalho = new Cabecalho();

  buscarLivesSubscription: Subscription;
  forkSubscription: Subscription;

  constructor(
    public youtubeService: YoutubeService,
    public helper: HelperService,
    public sanitizer: DomSanitizer,
    public loadingService: LoadingService,
    public siteService: SiteService,
  ) { }

  ngOnInit() {

    if (localStorage.getItem('cabecalhos')) {
      this.cabecalho = JSON.parse(localStorage.getItem('cabecalhos'));
    }

    let canal1 = this.youtubeService.getActivities('UCOVlX5lOW47QiEMbyWTdElA', 4);
    let canal2 = this.youtubeService.getActivities('UCcWF13v_IF5ztHcTbBJKTbA', 4);
    this.buscarVideos([canal1, canal2]);
    this.buscarLives();
    this.helper.setCanonicalURL();
  }

  ngOnDestroy() {
    this.buscarLivesSubscription.unsubscribe();
  }

  openModal(videoId) {
    const url = `https://www.youtube.com/embed/${videoId}?controls=1&showinfo=0&rel=0&autoplay=1&loop=0&mute=0&iv_load_policy=3&enablejsapi=0`;
    this.videoModalUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.videoModalOpen = true;
  }

  dismissModal() {
    this.videoModalUrl = '';
    this.videoModalOpen = false;
  }

  buscarVideos(reqs) {
    this.loadingService.present();
    this.forkSubscription = forkJoin(reqs)
      .subscribe((res: any) => {
        this.videos = res[0].items;
        this.videos2 = res[1].items;
        this.loadingService.dismiss();
      }, e => this.loadingService.dismiss())
  }

  buscarLives() {
    this.buscarLivesSubscription = this.siteService.buscarLives(-99, -99)
      .subscribe((res: Live[]) => this.lives = res);
  }

}
