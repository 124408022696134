<app-banner-interno [title]="title" [titleBold]="titleBold" [backgroundImage]="background"></app-banner-interno>

<section class="teams">
    <div class="container">
        <div class="teams__title">
            ESCOLHA UM TIME
        </div>
        <div class="teams-list">
            <div *ngFor="let time of times; let i = index;" class="teams-item">
                <h2 class="teams-item__title">{{time.nome}}</h2>
                <a class="teams-item__box" [title]="time.nome" (click)="verTime(time)">
                    <div class="teams-item__overlay">
                        <span class="teams-item__button">
                            VER TIME
                        </span>
                    </div>
                    <div class="teams-item__image">
                        <img [defaultImage]="'assets/image-placeholder.gif'" [lazyLoad]="helper.renderImageHeight(time.imagemPequena, 320)"
                            [errorImage]="'assets/image-error-placeholder.jpg'" [alt]="time.nome" [title]="time.nome" loading="lazy">
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>