import { Injectable, Inject } from "@angular/core";
import { NgForm } from "@angular/forms";
import * as moment from "moment";
import { DOCUMENT } from "@angular/common";
import { GlobalService } from "./global.service";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  moment;
  emailRegex: RegExp;

  constructor(@Inject(DOCUMENT) private dom, public global: GlobalService) {
    moment.locale("pt-Br");
    this.moment = moment;
    this.emailRegex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  formMarkAllTouched(form: NgForm) {
    (<any>Object).values(form.controls).forEach((control) => {
      control.markAsTouched();
      if (control.controls) {
        (<any>Object)
          .values(control.controls)
          .forEach((c) => this.formMarkAllTouched(c));
      }
    });
  }

  getYoutubeId(url: string) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement("link");
    link.setAttribute("rel", "canonical");
    this.dom.head.appendChild(link);
    link.setAttribute("href", canURL);
  }

  renderImage(path: string, width: number): string {
    return `${this.global.apiUrl}/site/imagem/renderizar?path=${path}&width=${width}`;
  }

  renderImageHeight(path: string, height: number): string {
    return `${this.global.apiUrl}/site/imagem/renderizar?path=${path}&height=${height}`;
  }
}
