<app-banner-interno [title]="'Contato'" [titleBold]="''"
    [backgroundImage]="cabecalho.imagemContato || 'assets/banner.png'">
</app-banner-interno>

<section class="contato">
    <div class="container">
        <div class="contato-wrapper">
            <div class="col">
                <h2 class="contato__title">
                    Fale Conosco
                </h2>
                <hr>
                <p>
                    Gostaria de nos mandar uma mensagem? É só preencher o formulário que entraremos em contato!
                    <br /><br />
                    Caso queira também você pode entrar em contato pelo nosso e-mail.
                    <br /><br />
                    <a [href]="'mailto:' + contatoGeral.email">{{contatoGeral.email}}</a>
                </p>
                <ul class="contato__social">
                    <li *ngIf="contatoGeral.linkTwitter">
                        <a [href]="contatoGeral.linkTwitter" target="_blank" title="Twitter">
                            <i class="fab fa-twitter twitter-color"></i>
                        </a>
                    </li>
                    <li *ngIf="contatoGeral.linkInstagram">
                        <a [href]="contatoGeral.linkInstagram" target="_blank" title="Instagram">
                            <i class="fab fa-instagram instagram-gradient"></i>
                        </a>
                    </li>
                    <li *ngIf="contatoGeral.linkFacebook">
                        <a [href]="contatoGeral.linkFacebook" target="_blank" title="Facebook">
                            <i class="fab fa-facebook-square facebook-color"></i>
                        </a>
                    </li>
                    <li *ngIf="contatoGeral.linkYoutube">
                        <a [href]="contatoGeral.linkYoutube" target="_blank" title="Youtube">
                            <i class="fab fa-youtube youtube-color"></i>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col">
                <form class="form" (ngSubmit)="enviarMensagem(form)" #form="ngForm">
                    <div class="form-control">
                        <input type="text" placeholder="Nome" [(ngModel)]="mensagem.nome" autocomplete="name"
                            name="nome" #nomeInput="ngModel" required>
                        <span class="form-control__hint" *ngIf="nomeInput.invalid && nomeInput.touched">*Preencha este
                            campo.</span>
                    </div>
                    <div class="form-control">
                        <input type="email" placeholder="E-mail" [pattern]="helper.emailRegex"
                            [(ngModel)]="mensagem.email" autocomplete="email" name="email" #emailInput="ngModel"
                            required>
                        <span class="form-control__hint" *ngIf="emailInput.invalid && emailInput.touched">*Preencha este
                            campo.</span>
                    </div>
                    <div class="form-control">
                        <input type="tel" placeholder="Telefone" [mask]="phoneMask" [(ngModel)]="mensagem.telefone"
                            (ngModelChange)="changePhoneMask($event)" [dropSpecialCharacters]="false" autocomplete="tel"
                            name="telefone" #telefoneInput="ngModel" required>
                        <span class="form-control__hint"
                            *ngIf="telefoneInput.invalid && telefoneInput.touched">*Preencha este campo.</span>
                    </div>
                    <div class="form-control third">
                        <select name="estado" #estadoInput="ngModel" id="estado" [(ngModel)]="mensagem.estado" required>
                            <option [value]="undefined" selected hidden>Estado</option>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                        </select>
                        <i class="fas fa-chevron-down"></i>
                        <span class="form-control__hint" *ngIf="estadoInput.invalid && estadoInput.touched">*Preencha
                            este campo.</span>
                    </div>
                    <div class="form-control two-third">
                        <input type="text" placeholder="Cidade" [(ngModel)]="mensagem.cidade" name="cidade"
                            #cidadeInput="ngModel" required>
                        <span class="form-control__hint" *ngIf="cidadeInput.invalid && cidadeInput.touched">*Preencha
                            este campo.</span>
                    </div>
                    <div class="form-control">
                        <textarea name="mensagem" #mensagemInput="ngModel" placeholder="Mensagem"
                            [(ngModel)]="mensagem.mensagem" id="mensagem" rows="10" required></textarea>
                        <span class="form-control__hint"
                            *ngIf="mensagemInput.invalid && mensagemInput.touched">*Preencha este campo.</span>
                    </div>
                    <button type="submit" class="btn btn-default btn-block">
                        ENVIAR
                    </button>
                </form>
            </div>
        </div>
    </div>
</section>