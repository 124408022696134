import { ConfiguracaoJogo } from "./../../models/configuracao-jogo";
import { Jogador } from "./../../models/jogador.model";
import { Jogo } from "./../../models/jogo.model";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { Time } from "./../../models/time.model";
import { HelperService } from "./../../services/helper.service";
import { SiteService } from "./../../services/site.service";
import { Component, OnInit, HostListener } from "@angular/core";

@Component({
  selector: "app-times-interno",
  templateUrl: "./times-interno.component.html",
  styleUrls: ["./times-interno.component.scss"],
})
export class TimesInternoComponent implements OnInit {
  title: string = "Nossos times";
  titleBold: string = "";
  background: string = "assets/banner-time.png";

  athletes: any[] = [1, 2, 3, 4, 5, 6, 7, 8];
  openModal: boolean = false;

  jogadores: Jogador[] = [];
  jogador: Jogador = new Jogador();

  time: Time = new Time();
  jogo: Jogo = new Jogo();
  configuracao: ConfiguracaoJogo = new ConfiguracaoJogo();

  buscarTimeSubscription: Subscription;
  buscarJogoSubscription: Subscription;
  buscarJogadoresSubscription: Subscription;
  buscarConfiguracoesSubscription: Subscription;

  constructor(
    public siteService: SiteService,
    public helper: HelperService,
    public route: ActivatedRoute
  ) {}

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    let keyPressed = event.keyCode;
    if (keyPressed === 27) {
      if (this.openModal) {
        this.openModal = false;
      }
    }
  }

  ngOnInit() {
    this.route.params.subscribe((param) => {
      this.buscarTime(param.slug);
      this.buscarJogadores(param.id);
      this.helper.setCanonicalURL();
    });
    this.helper.setCanonicalURL();
  }

  ngOnDestroy() {
    this.buscarTimeSubscription.unsubscribe();
    this.buscarJogadoresSubscription.unsubscribe();

    if (this.buscarJogoSubscription) this.buscarJogoSubscription.unsubscribe();
  }

  buscarTime(id) {
    this.buscarTimeSubscription = this.siteService
      .buscarTime(id)
      .subscribe((res: any) => {
        this.time = res;
        this.buscarJogo(res.jogo.id);
      });
  }

  buscarJogo(idJogo) {
    this.buscarJogoSubscription = this.siteService
      .buscarJogo(idJogo)
      .subscribe((res: Jogo) => {
        this.jogo = res;
        this.title += ` - `;
        this.titleBold = this.time.nome;
        this.background = this.jogo.imagem;
      });
  }

  buscarJogadores(idTime) {
    this.buscarJogadoresSubscription = this.siteService
      .buscarJogadores(-99, -99, idTime)
      .subscribe((res: Jogador[]) => {
        this.jogadores = res;
      });
  }

  buscarConfiguracoesEmJogo(idJogador: number) {
    if (
      this.buscarConfiguracoesSubscription &&
      !this.buscarConfiguracoesSubscription.closed
    ) {
      this.buscarConfiguracoesSubscription.unsubscribe();
    }
    this.buscarConfiguracoesSubscription = this.siteService
      .buscarConfiguracoesEmJogo(this.time.jogo.id, idJogador)
      .subscribe((res: any) => (this.configuracao = res));
  }

  present(jogador: Jogador) {
    this.jogador = jogador;
    this.openModal = true;
    this.buscarConfiguracoesEmJogo(jogador.id);
  }
}
